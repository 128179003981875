class PasswordSection extends HTMLElement {
  constructor() {
    super();
    // Wait for DOM content to be loaded before initializing
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.modal = document.querySelector('#PopupModal-password');
    this.passwordInput = document.querySelector('#Password');
    this.toggleButton = document.querySelector('#togglePassword');

    // Get SVG content from data attributes set in password-coming-soon.liquid
    this.eyeOpenSvg = this.getAttribute('data-eye-svg');
    this.eyeClosedSvg = this.getAttribute('data-eye-slash-svg');

    this.setupModalBehavior();
    this.setupPasswordInput();
    this.setupPasswordToggle();
  }
  // Show the password modal if there's an error message
  setupModalBehavior() {
    if (this.modal && this.modal.querySelector('.form__message')) {
      setTimeout(() => this.modal.show(this.modal.querySelector('button')), 100);
    }
  }
  // Remove error messages when the user starts typing
  setupPasswordInput() {
    if (this.passwordInput) {
      this.passwordInput.addEventListener('input', () => {
        const errorMessage = this.passwordInput.closest('form').querySelector('.form__message');
        if (errorMessage) {
          errorMessage.remove();
          this.passwordInput.closest('.password-field').classList.remove('password-field--error');
          this.passwordInput.removeAttribute('aria-invalid');
          this.passwordInput.removeAttribute('aria-describedby');
        }
      });
    }
  }
  // Toggle password visibility
  setupPasswordToggle() {
    if (this.toggleButton && this.passwordInput) {
      this.toggleButton.addEventListener('click', () => {
        const type = this.passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
        this.passwordInput.setAttribute('type', type);

        // Toggle icon using the SVG content from data attributes
        if (type === 'password') {
          this.toggleButton.innerHTML = this.eyeClosedSvg;
        } else {
          this.toggleButton.innerHTML = this.eyeOpenSvg;
        }
      });
    }
  }
}

customElements.define('password-section', PasswordSection);
